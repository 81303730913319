@import '../../style/themes/index';
@import '../../style/mixins/index';

@steps-prefix-cls: ~'@{ant-prefix}-steps';

.@{steps-prefix-cls} {
  &-rtl {
    direction: rtl;
  }
}

.@{steps-prefix-cls}-item {
  &-icon {
    .@{steps-prefix-cls}-rtl & {
      margin-right: 0;
      margin-left: 8px;
    }
  }

  &-tail {
    .@{steps-prefix-cls}-rtl & {
      right: 0;
      left: auto;
    }
  }

  &-title {
    .@{steps-prefix-cls}-rtl & {
      padding-right: 0;
      padding-left: 16px;
    }

    &::after {
      .@{steps-prefix-cls}-rtl & {
        right: 100%;
        left: auto;
      }
    }
  }
}

.@{steps-prefix-cls}-horizontal:not(.@{steps-prefix-cls}-label-vertical) {
  .@{steps-prefix-cls}-item {
    .@{steps-prefix-cls}-rtl& {
      margin-right: 0;
      margin-left: 16px;
    }

    &:last-child {
      .@{steps-prefix-cls}-rtl& {
        margin-left: 0;
      }
    }
    &:last-child .@{steps-prefix-cls}-item-title {
      .@{steps-prefix-cls}-rtl& {
        padding-left: 0;
      }
    }
  }
}

// custom-icon
.@{steps-prefix-cls}-item-custom {
  .@{steps-prefix-cls}-item-icon {
    > .@{steps-prefix-cls}-icon {
      .@{steps-prefix-cls}-rtl & {
        right: 0.5px;
        left: auto;
      }
    }
  }
}

// nav
.@{steps-prefix-cls}-navigation {
  &.@{steps-prefix-cls}-small {
    .@{steps-prefix-cls}-item {
      &-container {
        .@{steps-prefix-cls}-rtl& {
          margin-right: -12px;
          margin-left: 0;
        }
      }
    }
  }

  .@{steps-prefix-cls}-item {
    &-container {
      .@{steps-prefix-cls}-rtl& {
        margin-right: -16px;
        margin-left: 0;
        text-align: right;
      }
      .@{steps-prefix-cls}-item-title {
        .@{steps-prefix-cls}-rtl& {
          padding-left: 0;
        }
      }
    }

    &::after {
      .@{steps-prefix-cls}-rtl& {
        right: 100%;
        left: auto;
        margin-right: -2px;
        margin-left: 0;
        transform: rotate(225deg);
      }
    }
  }
}

// small
.@{steps-prefix-cls}-small {
  &.@{steps-prefix-cls}-horizontal:not(.@{steps-prefix-cls}-label-vertical)
    .@{steps-prefix-cls}-item {
    .@{steps-prefix-cls}-rtl& {
      margin-right: 0;
      margin-left: 12px;
    }

    &:last-child {
      .@{steps-prefix-cls}-rtl& {
        margin-left: 0;
      }
    }
  }

  .@{steps-prefix-cls}-item-title {
    .@{steps-prefix-cls}-rtl& {
      padding-right: 0;
    }
  }
}

// vertical
.steps-vertical() {
  .@{steps-prefix-cls}-item {
    &-icon {
      .@{steps-prefix-cls}-rtl& {
        float: right;
        margin-right: 0;
        margin-left: 16px;
      }
    }
  }

  > .@{steps-prefix-cls}-item
    > .@{steps-prefix-cls}-item-container
    > .@{steps-prefix-cls}-item-tail {
    .@{steps-prefix-cls}-rtl& {
      right: 16px;
      left: auto;
    }
  }

  &.@{steps-prefix-cls}-small .@{steps-prefix-cls}-item-container {
    .@{steps-prefix-cls}-item-tail {
      .@{steps-prefix-cls}-rtl& {
        right: 12px;
        left: auto;
      }
    }
  }
}

// label
.@{steps-prefix-cls}-label-vertical {
  .@{steps-prefix-cls}-item {
    &-title {
      .@{steps-prefix-cls}-rtl& {
        padding-left: 0;
      }
    }
  }
}
